<template>
  <div>
    <Header :customstyle="{ background: '#0C1913' }">
      <template slot="title">操作指南</template>
    </Header>
    <div class="content">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多记录了"
            @load="onLoad"
            >
                <div class="cell" v-for="(item, index) in listdata" :key="index" @click="clickDetail(item.id)">
                    <div class="leftView">
                        <div class="txt van-multi-ellipsis--l3">
                            {{item.title}}
                        </div>
                        <div class="time">
                            {{item.utime}}
                        </div>
                    </div>
                    <div class="rightView">
                        <img class="img" :src="item.images_url" />
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import {getGuideList} from '@/request/api'
import Header from "../../components/header.vue";
export default {
    components: {
        Header
    },
    data() {
        return {
            listdata: [
            ],
            refreshing: false,
            loading: false,
            finished: false,
            currentPage:1
        }
    },
    methods: {
        clickDetail (queryid) {
            console.log(queryid)
            this.$router.push({
                path:'/home/mine/operation/operatedetail',
                query: {
                    id:queryid
                }
            })
        },
        onRefresh() {
           
            // 清空列表数据
            this.finished = false;
            this.currentPage = 1;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.onLoad();
        },
        onLoad() {
            this.getDataList();
            this.currentPage++;
        },
        getDataList() {
             const params = {
                cate: 1,
                page:this.currentPage,
                limit: '20'
            }
            getGuideList(params).then((res)=> {
                if (this.refreshing) {
                    this.listdata = [];
                    this.refreshing = false;
                }
                this.listdata = res.data.list
                this.loading = false;
                if (res.data.list.length < 20) {
                    this.finished = true;
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    background: #0C1913;
    min-height: 100vh;
    padding: 0 32px;
    .cell {
        padding: 32px 0px;
        border-bottom: 1px solid ;
        color: rgba(139,166,149,1);
        display: flex;
        justify-content: space-between;
        .leftView {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .txt {
                color: rgba(214,255,227,1);
                font-size: 32px;
                font-weight: 700;
                font-family: "PingFang SC";
            }
            .time {
                color: rgba(139,166,149,1);
                font-size: 24px;
                font-weight: 400;
                font-family: "PingFang SC";
            }
        }
        .rightView {
            .img {
                width: 240px;
                height: 240px;
                border-radius: 16px;
                opacity: 1;
                background: rgba(217,217,217,1);
            }
        }
    }
}

</style>